import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';  // Import Firebase Storage

const firebaseConfig = {
  apiKey: "AIzaSyAnNZFV6k-9ZpokQR_d5syekQU9JgHQEa8",
  authDomain: "lexiai.lexdoo.com",
  projectId: "lexdo-7b0b1",
  storageBucket: "lexdo-7b0b1.appspot.com",
  messagingSenderId: "323278730681",
  appId: "1:323278730681:web:82a5e0300cea57b897f268",
  measurementId: "G-5VQFBFWKDZ"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);  // Add this line to access Firestore
export const storage = getStorage(app);
