import React, { useState, useRef, useEffect } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { addDoc, collection, getDoc, doc, updateDoc, query, where, getDocs } from 'firebase/firestore';
import { storage, db, auth } from '../../firebaseConfig'; // Firebase setup
import './WatermarkPdf.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';

import eg1 from '../../assets/watermakPdf/9.jpg'
import eg2 from '../../assets/watermakPdf/10.jpg'
import backIcon from '../../assets/back.png'

const images = [
  { title: 'Layer', url: eg1 },
  { title: 'Watermark Text', url: eg2 },
];


const ExampleModal = ({ isOpen, onClose, image }) => {
  if (!isOpen || !image) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-slider">
        <h3>{image.title}</h3>
        <img src={image.url} alt={image.title} style={{ width: '100%', height: 'auto' }} />
        <button
          onClick={onClose}
          style={{ marginTop: 20, backgroundColor: 'green', color: '#fff', padding: '8px 12px', border: 'none', borderRadius: '5px', fontSize: '16px' }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

const WatermarkPdf = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [watermarkMode, setWatermarkMode] = useState('text');
  const [watermarkText, setWatermarkText] = useState('');
  const [watermarkImage, setWatermarkImage] = useState(null);
  const [verticalPosition, setVerticalPosition] = useState('middle');
  const [horizontalPosition, setHorizontalPosition] = useState('center');
  const [verticalAdjustment, setVerticalAdjustment] = useState(0);
  const [horizontalAdjustment, setHorizontalAdjustment] = useState(0);
  const [layer, setLayer] = useState('above');
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [toolPrice, setToolPrice] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [watermarkedFileUrl, setWatermarkedFileUrl] = useState(null);
  const fileInputRef = useRef(null);
  const watermarkImageInputRef = useRef(null);
  const navigate = useNavigate(); // Hook for navigation
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const PUBLIC_KEY = 'project_public_89bba9932e7a274620c5d22eb4ce981a_9dOWN7a1ea85b9a31ff5f2d6d0d3924dc594b';
  const AUTH_API_URL = 'https://api.ilovepdf.com/v1/auth';

  useEffect(() => {
    const fetchToolPrice = async () => {
      try {
        const toolsCollection = collection(db, 'toolsPrice');
        const q = query(toolsCollection, where('tool', '==', 'Watermark PDF'));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const toolDoc = querySnapshot.docs[0];
          setToolPrice(toolDoc.data().price);
        } else {
          setErrorMessage('Tool price not found.');
        }
      } catch (error) {
        setErrorMessage('Error fetching tool price.');
      }
    };

    fetchToolPrice();
  }, []);

  const handleFileSelection = (event) => {
    setSelectedFile(event.target.files[0]);
    setErrorMessage(null);
  };

  const handleWatermarkModeChange = (event) => {
    setWatermarkMode(event.target.value);
    setErrorMessage(null);
  };

  const handleWatermarkTextChange = (event) => {
    setWatermarkText(event.target.value);
  };

  const handleWatermarkImageSelection = (event) => {
    const file = event.target.files[0];
    resizeImage(file, 500, 500)
      .then((resizedImage) => {
        setWatermarkImage(resizedImage);
        setErrorMessage(null);
      })
      .catch((error) => {
        setErrorMessage('Failed to resize image.');
        console.error('Resize error:', error);
        setWatermarkImage(null);
        watermarkImageInputRef.current.value = '';
      });
  };

  const resizeImage = (file, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const width = img.width;
        const height = img.height;

        let newWidth = width;
        let newHeight = height;

        // Resize image proportionally
        if (width > maxWidth || height > maxHeight) {
          if (width > height) {
            newHeight = Math.round((height * maxWidth) / width);
            newWidth = maxWidth;
          } else {
            newWidth = Math.round((width * maxHeight) / height);
            newHeight = maxHeight;
          }
        }

        canvas.width = newWidth;
        canvas.height = newHeight;

        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        canvas.toBlob(
          (blob) => {
            if (blob) {
              const resizedFile = new File([blob], 'resized_watermark.png', {
                type: 'image/png',
              });
              resolve(resizedFile);
            } else {
              reject(new Error('Image resizing failed.'));
            }
          },
          'image/png',
          1
        );
      };
      img.onerror = (error) => reject(error);
    });
  };

  const confirmProcess = () => {
    setIsModalOpen(true);
  };

  const handleApplyWatermark = async () => {
    if (!selectedFile) {
      setErrorMessage('Please select a PDF file to watermark.');
      return;
    }

    if (watermarkMode === 'text' && !watermarkText.trim()) {
      setErrorMessage('Please enter the watermark text.');
      return;
    }

    if (watermarkMode === 'image' && !watermarkImage) {
      setErrorMessage('Please select an image for the watermark.');
      return;
    }

    setIsProcessing(true);
    setErrorMessage(null);
    setIsModalOpen(false); // Close modal

    try {
      const token = await getToken();
      const { server, task } = await startTask(token, 'watermark');

      const serverFilename = await uploadFile(server, task, selectedFile, token);
      let watermarkImageFilename = null;

      if (watermarkMode === 'image') {
        watermarkImageFilename = await uploadFile(server, task, watermarkImage, token);
      }

      const processedFileResponse = await processFile(
        server,
        task,
        serverFilename,
        selectedFile.name,
        watermarkMode,
        watermarkText.trim(),
        watermarkImageFilename,
        token
      );

      // Download the watermarked PDF from the API
      const watermarkedPdfBlob = await downloadFile(server, task, token);

      // Upload the watermarked PDF to Firebase
      const downloadUrl = await uploadPdfToFirebase(watermarkedPdfBlob);
      setWatermarkedFileUrl(downloadUrl);

      setConfirmationMessage(`${toolPrice} coins have been deducted from your wallet.`);
      await deductAmountFromWallet(auth.currentUser.uid, toolPrice);
    } catch (error) {
      setErrorMessage('An error occurred while applying the watermark. Please try again later.');
    } finally {
      setIsProcessing(false);
    }
  };

  const resetPage = () => {
    setSelectedFile(null);
    setWatermarkMode('text');
    setWatermarkText('');
    setWatermarkImage(null);
    setIsProcessing(false);
    setErrorMessage(null);
    setConfirmationMessage('');
    setIsCopied(false);
    setWatermarkedFileUrl(null);

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    if (watermarkImageInputRef.current) {
      watermarkImageInputRef.current.value = '';
    }
  };

  const getToken = async () => {
    const response = await fetch(AUTH_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ public_key: PUBLIC_KEY }),
    });
    const data = await response.json();
    return data.token;
  };

  const startTask = async (token, tool) => {
    const response = await fetch(`https://api.ilovepdf.com/v1/start/${tool}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();
    if (!response.ok || !data.server || !data.task) {
      throw new Error('Failed to start task.');
    }

    return data;
  };

  const uploadFile = async (server, task, file, token) => {
    const formData = new FormData();
    formData.append('task', task);
    formData.append('file', file);

    const response = await fetch(`https://${server}/v1/upload`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: formData,
    });

    const data = await response.json();
    return data.server_filename;
  };

  const processFile = async (
    server,
    task,
    serverFilename,
    originalFilename,
    mode,
    text,
    watermarkImageFilename,
    token
  ) => {
    const requestBody = {
      task: task,
      tool: 'watermark',
      files: [
        {
          server_filename: serverFilename,
          filename: originalFilename,
        },
      ],
      mode: mode,
      text: mode === 'text' ? text : undefined,
      image: mode === 'image' ? watermarkImageFilename : undefined,
      position: 'center',
      transparency: 50,
      pages: 'all',
      vertical_position: verticalPosition,
      horizontal_position: horizontalPosition,
      vertical_position_adjustment: verticalAdjustment,
      horizontal_position_adjustment: horizontalAdjustment,
      layer: layer, // Layer option (above or below content)
    };

    const response = await fetch(`https://${server}/v1/process`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Process failed: ${errorText}`);
    }

    return response.json();
  };

  const downloadFile = async (server, task, token) => {
    const response = await fetch(`https://${server}/v1/download/${task}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Download failed.');
    }

    const blob = await response.blob();
    return blob;
  };

  const uploadPdfToFirebase = async (pdfBlob) => {
    const fileName = `watermarked_pdf_${Date.now()}.pdf`;
    const storageRef = ref(storage, `watermarkedPdfs/${fileName}`);
    const snapshot = await uploadBytes(storageRef, pdfBlob);
    const downloadUrl = await getDownloadURL(snapshot.ref);

    await addDoc(collection(db, 'watermarkedPdfs'), {
      downloadUrl,
      timestamp: new Date(),
    });

    return downloadUrl;
  };

  const deductAmountFromWallet = async (userId, price) => {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const currentBalance = userData.balance || 0;

      if (currentBalance >= price) {
        const newBalance = currentBalance - price;
        await updateDoc(userDocRef, { balance: newBalance });
      } else {
        throw new Error('Insufficient coins in your wallet.');
      }
    } else {
      throw new Error('User not found.');
    }
  };

  const copyLinkToClipboard = async () => {
    if (!watermarkedFileUrl) {
      setConfirmationMessage('No link to copy.');
      return;
    }

    try {
      await navigator.clipboard.writeText(watermarkedFileUrl);
      setIsCopied(true);
      setConfirmationMessage('Watermarked PDF link copied to clipboard. Paste the copied link in any browser to download the pdf file.');
    } catch (error) {
      setConfirmationMessage('Failed to copy link.');
    }
  };

  // Open the image modal when clicking an image
  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsImageModalOpen(true); // Open image modal
  };

  // Close the image modal
  const closeImageModal = () => {
    setSelectedImage(null);
    setIsImageModalOpen(false);
  };

  return (
    <>
     <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '0px' }} onClick={() => navigate('/tools')}>
    <div className="back-button">
    <img src={backIcon} alt='back-btn' style={{width: '25px', height: '25px'}} />
    <button className='back-sub'>Back</button>
    </div>
    </div>
    <div className="watermark-pdf-container">
      <h1>Watermark PDF</h1>
      <p>Add a watermark to your PDF file.</p>
      {/* Image Container */}
      <div className="watermark-previmg-container">
        {images.map((image, index) => (
          <div key={index} className="watermark-previmg-item" onClick={() => handleImageClick(image)}>
            <img src={image.url} alt={image.title} className="watermark-previmg" />
            <p>{image.title}</p>
          </div>
        ))}
      </div>

      <div className="file-upload-container">
        <input
          type="file"
          accept=".pdf"
          onChange={handleFileSelection}
          ref={fileInputRef}
          className="file-input"
        />
        <button
          className="file-upload-button"
          onClick={() => fileInputRef.current.click()}
          style={{ display: selectedFile ? 'none' : 'block' }}
        >
          Select PDF file
        </button>
      </div>

      {selectedFile && (
        <div className="pdf-settings">
          <div className="pdf-preview">
            <div className='pdf-thumbnail'>
            <span>FileName: {selectedFile.name}</span>
            <span>FileSize: {(selectedFile.size / 1024 / 1024).toFixed(2)} MB</span>
            </div>
          </div>
          <label style={{textAlign: 'center'}}>
            Watermark Mode:
            <select value={watermarkMode} onChange={handleWatermarkModeChange}>
              <option value="text">Text</option>
              <option value="image">Image</option>
            </select>
          </label>

          {watermarkMode === 'text' && (
            <label style={{ textAlign: 'center' }}>
              Watermark Text:
              <input
                type="text"
                placeholder="Type watermark text"
                value={watermarkText}
                onChange={handleWatermarkTextChange}
              />
            </label>
          )}

          {watermarkMode === 'image' && (
            <div>
              <label>
                Select Watermark Image:
                <input
                  type="file"
                  accept='.jpg,.png'
                  onChange={handleWatermarkImageSelection}
                  ref={watermarkImageInputRef}
                  className="file-input"
                />
                <button
                  className="file-upload-button"
                  onClick={() => watermarkImageInputRef.current.click()}
                  style={{ display: watermarkImage ? 'none' : 'block' }}
                >
                  Select Image
                </button>
              </label>

              {watermarkImage && (
                <div className="image-preview" style={{marginBottom: '30px'}}>
                  <img src={URL.createObjectURL(watermarkImage)} alt="Watermark Preview" />
                  <span>{watermarkImage.name}</span>
                </div>
              )}
            </div>
          )}

          {watermarkMode === 'text' && (
            <div>
          <label style={{textAlign: 'center'}}>
            Vertical Position:
            <select value={verticalPosition} onChange={(e) => setVerticalPosition(e.target.value)}>
              <option value="top">Top</option>
              <option value="middle">Middle</option>
              <option value="bottom">Bottom</option>
            </select>
          </label>

          <label style={{textAlign: 'center'}}>
            Horizontal Position:
            <select value={horizontalPosition} onChange={(e) => setHorizontalPosition(e.target.value)}>
              <option value="left">Left</option>
              <option value="center">Center</option>
              <option value="right">Right</option>
            </select>
          </label>
          </div>
          )}

          {/* <label>
            Vertical Adjustment (px):
            <input
              type="number"
              value={verticalAdjustment}
              onChange={(e) => setVerticalAdjustment(parseInt(e.target.value, 10))}
            />
          </label>

          <label>
            Horizontal Adjustment (px):
            <input
              type="number"
              value={horizontalAdjustment}
              onChange={(e) => setHorizontalAdjustment(parseInt(e.target.value, 10))}
            />
          </label> */}

          <label>
            Layer:
            <select value={layer} onChange={(e) => setLayer(e.target.value)}>
              <option value="above">Above content</option>
              <option value="below">Below content</option>
            </select>
          </label>

          <button
            className="crop-button"
            onClick={confirmProcess}
            disabled={isProcessing}
          >
            {isProcessing ? 'Processing...' : 'Apply Watermark'}
          </button>
        </div>
      )}

<ExampleModal isOpen={isImageModalOpen} onClose={closeImageModal} image={selectedImage} />


      {watermarkedFileUrl && (
        <div className="share-options">
          <button className="share-button" onClick={copyLinkToClipboard}>
            {isCopied ? 'Copied!' : 'Copy Link'}
          </button>
        </div>
      )}

      {errorMessage && <div className="error-message" style={{marginTop: '25px'}}>{errorMessage}</div>}
      {confirmationMessage && (
        <div className="confirmation-message" style={{marginTop: '25px'}}>{confirmationMessage}</div>
      )}

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <p>Are you sure you want to deduct {toolPrice} coins for the watermark process?</p>
            <div className="modal-buttons">
              <button className="modal-confirm" onClick={handleApplyWatermark}>
                Confirm
              </button>
              <button className="modal-cancel" onClick={() => setIsModalOpen(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default WatermarkPdf;
