// SessionList.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, onSnapshot, deleteDoc, doc, setDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { v4 as uuidv4 } from 'uuid';
import deleteIcon from '../assets/delete1.png';

const SessionList = ({ userId }) => {
  const [sessions, setSessions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      const sessionsRef = collection(db, 'LexiAiChats', userId, 'sessions');
      const unsubscribe = onSnapshot(sessionsRef, (snapshot) => {
        const loadedSessions = snapshot.docs.map((doc, index) => ({
          id: doc.id,
          name: `Session ${index + 1}`,
          ...doc.data(),
        }));
        setSessions(loadedSessions);
      });
      return () => unsubscribe();
    }
  }, [userId]);

  const handleDeleteSession = async (sessionId) => {
    const confirmed = window.confirm("Are you sure you want to delete this session?");
    if (confirmed) {
      await deleteDoc(doc(db, 'LexiAiChats', userId, 'sessions', sessionId));
    }
  };

  const handleStartNewChat = async () => {
    const newSessionId = uuidv4();
    await setDoc(doc(db, 'LexiAiChats', userId, 'sessions', newSessionId), { chats: [] });
    navigate(`/chat/${newSessionId}`);
  };

  return (
    <div className="session-list">
      <h2>My Sessions</h2>
      <button onClick={handleStartNewChat}>Start New Chat</button>
      <ul>
        {sessions.map((session) => (
          <li key={session.id} onClick={() => navigate(`/chat/${session.id}`)}>
            {session.name}
            <img
              src={deleteIcon}
              alt="Delete"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteSession(session.id);
              }}
              style={{ cursor: 'pointer', marginLeft: 10 }}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SessionList;
