import React, { useState, useRef, useEffect } from 'react';
import { getDoc, doc, updateDoc, query, where, getDocs, collection } from 'firebase/firestore';
import { db, auth } from '../../firebaseConfig'; // Firebase setup
import './ValidatePdfA.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';

import backIcon from '../../assets/back.png'

const ValidatePdfA = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [validationResult, setValidationResult] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [toolPrice, setToolPrice] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate(); // Hook for navigation

  const PUBLIC_KEY = 'project_public_89bba9932e7a274620c5d22eb4ce981a_9dOWN7a1ea85b9a31ff5f2d6d0d3924dc594b';
  const AUTH_API_URL = 'https://api.ilovepdf.com/v1/auth';

  useEffect(() => {
    // Fetch tool price from Firestore
    const fetchToolPrice = async () => {
      try {
        const toolsCollection = collection(db, 'toolsPrice');
        const q = query(toolsCollection, where('tool', '==', 'Validate PDF/A'));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const toolDoc = querySnapshot.docs[0];
          setToolPrice(toolDoc.data().price); // Set tool price from Firestore
        } else {
          setErrorMessage('Tool price not found.');
        }
      } catch (error) {
        setErrorMessage('Error fetching tool price.');
      }
    };

    fetchToolPrice();
  }, []);

  const handleFileSelection = (event) => {
    setSelectedFile(event.target.files[0]);
    setErrorMessage(null);
    setValidationResult(null);
    setConfirmationMessage('');
  };

  const confirmProcess = () => {
    setIsModalOpen(true); // Open confirmation modal
  };

  const handleValidatePdfA = async () => {
    if (!selectedFile) {
      setErrorMessage('Please select a PDF file to validate.');
      return;
    }

    setIsProcessing(true);
    setErrorMessage(null);
    setIsModalOpen(false); // Close modal

    try {
      const token = await getToken();
      const { server, task } = await startTask(token, 'validatepdfa');

      const serverFilename = await uploadFile(server, task, selectedFile, token);

      const validationResponse = await processFile(server, task, serverFilename, selectedFile.name, token);
      
      const status = validationResponse.validations[0].status || 'Unknown';
      setValidationResult({
        message: status === "Conformant" ? "The file conforms to the PDF/A standard." : "The file does not conform to the PDF/A standard.",
        isConformant: status === "Conformant"
      });

      setConfirmationMessage(`${toolPrice} coins have been deducted from your wallet.`);
      await deductAmountFromWallet(auth.currentUser.uid, toolPrice);
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred while validating the file. Please try again later.');
    } finally {
      setIsProcessing(false);
    }
  };

  const resetPage = () => {
    setSelectedFile(null);
    setValidationResult(null);
    setIsProcessing(false);
    setErrorMessage(null);

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const getToken = async () => {
    const response = await fetch(AUTH_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ public_key: PUBLIC_KEY })
    });

    const data = await response.json();
    return data.token;
  };

  const startTask = async (token, tool) => {
    const response = await fetch(`https://api.ilovepdf.com/v1/start/${tool}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    const text = await response.text();
    if (!response.ok || !text) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    const data = JSON.parse(text);
    return data;
  };

  const uploadFile = async (server, task, file, token) => {
    const formData = new FormData();
    formData.append('task', task);
    formData.append('file', file);

    const response = await fetch(`https://${server}/v1/upload`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: formData
    });

    if (!response.ok) {
      throw new Error(`Upload failed with status ${response.status}`);
    }

    const data = await response.json();
    return data.server_filename;
  };

  const processFile = async (server, task, serverFilename, originalFilename, token) => {
    const requestBody = {
      task: task,
      tool: 'validatepdfa',
      files: [
        {
          server_filename: serverFilename,
          filename: originalFilename,
        }
      ]
    };

    const response = await fetch(`https://${server}/v1/process`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Process failed with status ${response.status}: ${errorText}`);
    }

    const data = await response.json();
    return data;
  };

  const deductAmountFromWallet = async (userId, price) => {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const currentBalance = userData.balance || 0;

      if (currentBalance >= price) {
        const newBalance = currentBalance - price;
        await updateDoc(userDocRef, { balance: newBalance });
      } else {
        throw new Error('Insufficient coins in your wallet.');
      }
    } else {
      throw new Error('User not found.');
    }
  };

  return (
    <>
     <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '0px' }} onClick={() => navigate('/tools')}>
    <div className="back-button">
    <img src={backIcon} alt='back-btn' style={{width: '25px', height: '25px'}} />
    <button className='back-sub'>Back</button>
    </div>
    </div>
    <div className="validate-pdfa-container">
      <h1>Validate PDF/A</h1>
      <p>Validate your PDF files for PDF/A conformance and determine if they are compliant.</p>

      <div className="file-upload-container">
        <input 
          type="file" 
          onChange={handleFileSelection} 
          ref={fileInputRef}
          className="file-input"
        />
        <button
          className="file-upload-button"
          onClick={() => fileInputRef.current.click()}
          style={{ display: selectedFile ? 'none' : 'block' }}
        >
          Select PDF file
        </button>
      </div>

      {selectedFile && (
        <div className="pdf-preview-container">
          <div className="pdf-preview">
            <div className='pdf-thumbnail'>
            <span>FileName: {selectedFile.name}</span>
            <span>FileSize: {(selectedFile.size / 1024 / 1024).toFixed(2)} MB</span>
            </div>
          </div>
          <button 
            className="validate-button" 
            onClick={confirmProcess}
            disabled={isProcessing}
          >
            {isProcessing ? 'Processing...' : 'Validate PDF/A'}
          </button>
        </div>
      )}

      {validationResult && (
        <div className="validation-result" style={{marginTop: '30px'}}>
          <h3>Validation Result:</h3>
          <p
            style={{
              marginTop: 10,
              color: validationResult.isConformant ? 'green' : 'red',
            }}
          >
            {validationResult.message}
          </p>
        </div>
      )}

      {errorMessage && <div className="error" style={{marginTop: '25px'}}>{errorMessage}</div>}
      {confirmationMessage && <div className="confirmation-message" style={{marginTop: '25px'}}>{confirmationMessage}</div>}

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <p>Are you sure you want to deduct {toolPrice} coins for the PDF/A validation?</p>
            <div className="modal-buttons">
              <button className="modal-confirm" onClick={handleValidatePdfA}>Ok</button>
              <button className="modal-cancel" onClick={() => setIsModalOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default ValidatePdfA;
