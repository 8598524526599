import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import styles from './OfficialWebsites.module.css'; // Import the CSS module
import { useNavigate } from 'react-router-dom';

import backIcon from '../../assets/back.png'


const OfficialWebsites = () => {
  const [officialSites, setOfficialSites] = useState([]);
  const [filteredSites, setFilteredSites] = useState([]); // To store filtered results
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate(); // Hook for navigation
  
  useEffect(() => {
    fetchOfficialSites();
  }, []);

  // Fetch official sites from Firestore
  const fetchOfficialSites = async () => {
    try {
      let sitesQuery = query(collection(db, "officialSites"), orderBy("createdAt"));
      const snapshot = await getDocs(sitesQuery);
      const sitesData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setOfficialSites(sitesData);
      setFilteredSites(sitesData); // Set filtered sites initially to all fetched sites
    } catch (error) {
      console.error("Error fetching official sites:", error);
    }
  };

  // Handle real-time search functionality
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    const filtered = officialSites.filter(site => {
      // Check if any keyword in the array matches the search term
      const keywordMatch = site.keyword && Array.isArray(site.keyword)
        ? site.keyword.some(kw => kw.toLowerCase().includes(value.toLowerCase()))
        : false;

      return keywordMatch;
    });

    setFilteredSites(filtered); // Update the filtered sites based on search
  };

  return (
    <>
     <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '0px' }} onClick={() => navigate('/tools')}>
    <div className="back-button">
    <img src={backIcon} alt='back-btn' style={{width: '25px', height: '25px'}} />
    <button className='back-sub'>Back</button>
    </div>
    </div>
    <div className={styles.container}>
      <h1 style={{marginBottom: 10}}>Official Website Link</h1>
      <p style={{marginBottom: 10}}>Search official website links quickly by keywords</p>

      <input
        type="text"
        placeholder="Search by keyword..."
        value={searchTerm}
        onChange={handleSearch} // Trigger search as the user types
        className={styles.inputField}
      />

      <div className={styles.results}>
        {filteredSites.length > 0 ? (
          filteredSites.map(site => (
            <div key={site.id} className={styles.siteItem}>
              <a href={site.link} target="_blank" rel="noopener noreferrer">
                {site.title}
              </a>
            </div>
          ))
        ) : (
          <p className={styles.noResults}>No results found</p> // Show "No results found" when array is empty
        )}
      </div>
    </div>
    </>
  );
};

export default OfficialWebsites;
