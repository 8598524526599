import React, { useState } from 'react';
import axios from 'axios';

const BraveSearchComponent = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState(null);
  const [newsResults, setNewsResults] = useState(null);
  const [summary, setSummary] = useState('');

  const handleSearch = async () => {
    try {
      const response = await axios.get('https://us-central1-lexdo-7b0b1.cloudfunctions.net/braveSearchAPI/api/search', {
        params: {
          q: query,
          count: 10,
          search_lang: 'en',
          safesearch: 'moderate',
          summary: 1,
        },
      });

      setResults(response.data);

      const summarizerKey = response.data.summarizer?.key;
      if (summarizerKey) {
        const summaryResponse = await axios.get('https://us-central1-lexdo-7b0b1.cloudfunctions.net/braveSearchAPI/api/summarizer', {
          params: {
            key: summarizerKey,
            entity_info: 1,
          },
        });

        const cleanSummary = summaryResponse.data.summary
          .map((msg) => msg.data)
          .join(' ')
          .replace(/\*\*/g, '') // Remove asterisks used for bolding
          .replace(/(?:\r\n|\r|\n)/g, '<br>'); // Replace new lines with <br> tags

        setSummary(cleanSummary);
      }
    } catch (error) {
      console.error('Error fetching data from Brave Search API:', error);
    }
  };

  const handleNewsSearch = async () => {
    try {
      const response = await axios.get('https://us-central1-lexdo-7b0b1.cloudfunctions.net/braveSearchAPI/api/news', {
        params: {
          q: query,
          count: 10,
          country: 'us',
          search_lang: 'en',
          safesearch: 'moderate',
          spellcheck: 1,
        },
      });

      setNewsResults(response.data);
    } catch (error) {
      console.error('Error fetching data from Brave News Search API:', error);
    }
  };

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1>Search</h1>
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Enter your search query"
        style={{
          width: '80%',
          padding: '10px',
          margin: '10px 0',
          fontSize: '16px',
          borderRadius: '5px',
          border: '1px solid #ccc',
        }}
      />
      <button
        onClick={handleSearch}
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          borderRadius: '5px',
          backgroundColor: '#007bff',
          color: '#fff',
          border: 'none',
          cursor: 'pointer',
          marginRight: '10px',
        }}
      >
        Search
      </button>
      <button
        onClick={handleNewsSearch}
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          borderRadius: '5px',
          backgroundColor: '#28a745',
          color: '#fff',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        Search News
      </button>

      {summary && (
        <div style={{ marginTop: '20px', backgroundColor: '#f9f9f9', padding: '20px', borderRadius: '5px' }}>
          <h2 style={{ marginBottom: '10px', color: '#333' }}>Summary:</h2>
          <p style={{ lineHeight: '1.6', color: '#555' }} dangerouslySetInnerHTML={{ __html: summary }} />
        </div>
      )}

      {newsResults && (
        <div style={{ marginTop: '20px', backgroundColor: '#f9f9f9', padding: '20px', borderRadius: '5px' }}>
          <h2 style={{ marginBottom: '10px', color: '#333' }}>News Results:</h2>
          <ul>
            {newsResults.results.map((news, index) => (
              <li key={index}>
                <a href={news.url} target="_blank" rel="noopener noreferrer">
                  {news.title}
                </a>
                <p>{news.description}</p>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default BraveSearchComponent;
