import React, { useState, useRef, useEffect } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { addDoc, collection, getDoc, doc, updateDoc, query, where, getDocs } from 'firebase/firestore';
import { storage, db, auth } from '../../firebaseConfig';
import '../OfficeToPdf/OfficeToPdf.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';
import backIcon from '../../assets/back.png';

const PdfToWord = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [toolPrice, setToolPrice] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [docxFileUrl, setDocxFileUrl] = useState(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const API_KEY = 'secret_T2O8KhsBHi3g7H7P';
  const CONVERT_API_URL = 'https://v2.convertapi.com/convert/pdf/to/docx';

  useEffect(() => {
    const fetchToolPrice = async () => {
      try {
        const toolsCollection = collection(db, 'toolsPrice');
        const q = query(toolsCollection, where('tool', '==', 'PDF to Word'));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const toolDoc = querySnapshot.docs[0];
          setToolPrice(toolDoc.data().price);
        } else {
          setErrorMessage('Tool price not found.');
        }
      } catch (error) {
        setErrorMessage('Error fetching tool price.');
      }
    };

    fetchToolPrice();
  }, []);

  const handleFileSelection = (event) => {
    setSelectedFile(event.target.files[0]);
    setErrorMessage(null);
    setConfirmationMessage('');
    setIsCopied(false);
  };

  const confirmProcess = () => {
    setIsModalOpen(true);
  };

  const handleConvertToDocx = async () => {
    if (!selectedFile) {
      setErrorMessage('Please select a PDF file to convert.');
      return;
    }

    setIsProcessing(true);
    setErrorMessage(null);
    setIsModalOpen(false);

    try {
      const base64File = await convertFileToBase64(selectedFile);
      const response = await fetch(CONVERT_API_URL, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${API_KEY}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Parameters: [
            { Name: 'File', FileValue: { Name: selectedFile.name, Data: base64File } },
            { Name: 'StoreFile', Value: true },
          ],
        }),
      });

      if (!response.ok) {
        throw new Error('Conversion failed. Please try again.');
      }

      const result = await response.json();
      const docxUrl = result.Files[0].Url;
      const downloadUrl = await uploadDocxToFirebase(docxUrl);

      setDocxFileUrl(downloadUrl);
      setConfirmationMessage(`${toolPrice} coins have been deducted from your wallet.`);
      await deductAmountFromWallet(auth.currentUser.uid, toolPrice);
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred during conversion.');
    } finally {
      setIsProcessing(false);
    }
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadDocxToFirebase = async (docxUrl) => {
    try {
      const response = await fetch(docxUrl);
      const blob = await response.blob();
      const fileName = `pdf_to_docx_${Date.now()}.docx`;
      const storageRef = ref(storage, `pdfToDocx/${fileName}`);
      const snapshot = await uploadBytes(storageRef, blob);
      const downloadUrl = await getDownloadURL(snapshot.ref);

      await addDoc(collection(db, 'pdfToDocx'), {
        downloadUrl,
        timestamp: new Date(),
      });

      return downloadUrl;
    } catch (error) {
      throw new Error('Failed to upload DOCX to Firebase.');
    }
  };

  const deductAmountFromWallet = async (userId, price) => {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const currentBalance = userData.balance || 0;

      if (currentBalance >= price) {
        const newBalance = currentBalance - price;
        await updateDoc(userDocRef, { balance: newBalance });
      } else {
        throw new Error('Insufficient coins in your wallet.');
      }
    } else {
      throw new Error('User not found.');
    }
  };

  const copyLinkToClipboard = async () => {
    if (!docxFileUrl) {
      setConfirmationMessage('No link to copy.');
      return;
    }

    try {
      await navigator.clipboard.writeText(docxFileUrl);
      setIsCopied(true);
      setConfirmationMessage('Converted DOCX link copied to clipboard.');
    } catch (error) {
      setConfirmationMessage('Failed to copy link.');
    }
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '0px' }} onClick={() => navigate('/tools')}>
        <div className="back-button">
          <img src={backIcon} alt='back-btn' style={{width: '25px', height: '25px'}} />
          <button className='back-sub'>Back</button>
        </div>
      </div>
      <div className="office-to-pdf-container">
        <h1>Convert PDF to Word</h1>
        <p>Convert your PDF files to Word (DOCX) format.</p>
        <div className="language-support-message" style={{ color: 'red', marginTop: '20px' }}>
            Note: Only English language is supported for converting pdf to word (docx).
        </div>

        <div className="file-upload-container">
          <input 
            type="file"
            accept=".pdf"
            onChange={handleFileSelection} 
            ref={fileInputRef}
            className="file-input"
          />
          <button
            className="file-upload-button"
            onClick={() => fileInputRef.current.click()}
            style={{ display: selectedFile ? 'none' : 'block' }}
          >
            Select PDF file
          </button>
        </div>

        {selectedFile && (
          <div className="pdf-preview-container">
            <div className="pdf-thumbnail">
              <span>FileName: {selectedFile.name}</span>
              <span>FileSize: {(selectedFile.size / 1024 / 1024).toFixed(2)} MB</span>
            </div>

            <button 
              className="crop-button" 
              onClick={confirmProcess}
              disabled={isProcessing}
            >
              {isProcessing ? 'Processing...' : 'Convert to DOCX'}
            </button>
          </div>
        )}

        {docxFileUrl && (
          <div className="share-options">
            <button className="share-button" onClick={copyLinkToClipboard}>
              {isCopied ? 'Copied!' : 'Copy Link'}
            </button>
          </div>
        )}

        {errorMessage && <div className="error-message" style={{marginTop: '25px'}}>{errorMessage}</div>}
        {confirmationMessage && <div className="confirmation-message" style={{marginTop: '25px'}}>{confirmationMessage}</div>}

        {isModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <p>Are you sure you want to deduct {toolPrice} coins for the DOCX conversion?</p>
              <div className="modal-buttons">
                <button className="modal-confirm" onClick={handleConvertToDocx}>Ok</button>
                <button className="modal-cancel" onClick={() => setIsModalOpen(false)}>Cancel</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PdfToWord;
