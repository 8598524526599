import React, { useState, useRef } from 'react';
import './RotateImage.css'; // Import the CSS file

const RotateImage = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [rotationAngle, setRotationAngle] = useState(90); // Default rotation angle
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const fileInputRef = useRef(null);

  const PUBLIC_KEY = 'project_public_89bba9932e7a274620c5d22eb4ce981a_9dOWN7a1ea85b9a31ff5f2d6d0d3924dc594b';
  const AUTH_API_URL = 'https://api.ilovepdf.com/v1/auth';

  const handleFileSelection = (event) => {
    setSelectedFile(event.target.files[0]);
    setErrorMessage(null);
  };

  const handleRotationAngleChange = (event) => {
    setRotationAngle(event.target.value);
  };

  const handleRotateImage = async () => {
    if (!selectedFile) {
      setErrorMessage('Please select an image file to rotate.');
      return;
    }

    setIsProcessing(true);
    setErrorMessage(null);

    try {
      const token = await getToken();
      const { server, task } = await startTask(token, 'rotateimage');

      const serverFilename = await uploadFile(server, task, selectedFile, token);

      await processFile(server, task, serverFilename, selectedFile.name, token);
      
      await downloadFile(server, task, token);
      resetPage();
    } catch (error) {
      setErrorMessage('Sorry! iLoveIMG can\'t process all your files in one task. Too many Megapixel for image.');
    } finally {
      setIsProcessing(false);
    }
  };

  const resetPage = () => {
    setSelectedFile(null);
    setRotationAngle(90);
    setIsProcessing(false);
    setErrorMessage(null);

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const getToken = async () => {
    const response = await fetch(AUTH_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ public_key: PUBLIC_KEY })
    });

    const data = await response.json();
    return data.token;
  };

  const startTask = async (token, tool) => {
    const response = await fetch(`https://api.ilovepdf.com/v1/start/${tool}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    const text = await response.text();
    if (!response.ok || !text) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    const data = JSON.parse(text);
    if (!data.server || !data.task) {
      throw new Error('Failed to retrieve server or task ID from response');
    }

    return data;
  };

  const uploadFile = async (server, task, file, token) => {
    const formData = new FormData();
    formData.append('task', task);
    formData.append('file', file);

    const response = await fetch(`https://${server}/v1/upload`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: formData
    });

    if (!response.ok) {
      throw new Error(`Upload failed with status ${response.status}`);
    }

    const data = await response.json();
    return data.server_filename;
  };

  const processFile = async (server, task, serverFilename, originalFilename, token) => {
    const requestBody = {
      task: task,
      tool: 'rotateimage',
      files: [
        {
          server_filename: serverFilename,
          filename: originalFilename,
        }
      ],
      rotation_angle: parseInt(rotationAngle, 10), // Specify the rotation angle
    };

    const response = await fetch(`https://${server}/v1/process`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Process failed with status ${response.status}: ${errorText}`);
    }

    const data = await response.json();
    return data;
  };

  const downloadFile = async (server, task, token) => {
    const response = await fetch(`https://${server}/v1/download/${task}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Download failed with status ${response.status}`);
    }

    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'rotated-image.png'; // Set the name for the downloaded file
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(downloadUrl);
  };

  return (
    <div className="rotate-image-container">
      <h1>Rotate Image</h1>
      <p>Rotate your image by a specified angle.</p>

      <div className="file-upload-container">
        <input 
          type="file" 
          onChange={handleFileSelection} 
          ref={fileInputRef}
          className="file-input"
        />
        <button
          className="file-upload-button"
          onClick={() => fileInputRef.current.click()}
          style={{ display: selectedFile ? 'none' : 'block' }}
        >
          Select Image file
        </button>
      </div>

      {selectedFile && (
        <div className="image-settings">
          <label>
            Rotation Angle:
            <input 
              type="number" 
              min="0" 
              max="360" 
              value={rotationAngle} 
              onChange={handleRotationAngleChange}
              placeholder="Rotation angle (e.g., 90)"
            />
          </label>

          <button 
            className="rotate-button" 
            onClick={handleRotateImage}
            disabled={isProcessing}
          >
            {isProcessing ? 'Processing...' : 'Rotate Image'}
          </button>
        </div>
      )}

      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
};

export default RotateImage;
