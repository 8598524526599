import axios from 'axios';

const API_KEY = 'AIzaSyConUSymuWcrzZ_nFsbXuZQduLgRfYr9jc';  // Same API key

// Function to get the list of voices (optional)
export const getVoiceList = async () => {
  try {
    const response = await axios.get(
      `https://texttospeech.googleapis.com/v1/voices?key=${API_KEY}`
    );
    return response.data.voices;
  } catch (error) {
    console.error('Error fetching voice list:', error);
    return [];
  }
};

// Function to convert text to speech
// Function to convert text to speech
export const speakText = async (text) => {
  try {
    const response = await axios.post(
      `https://texttospeech.googleapis.com/v1/text:synthesize?key=${API_KEY}`,
      {
        input: {
          text: text,
        },
        voice: {
          languageCode: 'en-US',  // Change this to the desired language code
          name: 'en-US-Wavenet-F',  // Specify the voice name
          ssmlGender: 'FEMALE',  // Set the gender if required
        },
        audioConfig: {
          audioEncoding: 'MP3',
        },
      }
    );

    const audioContent = response.data.audioContent;
    const audioUrl = `data:audio/mp3;base64,${audioContent}`;
    return audioUrl;  // Return the base64-encoded audio URL
  } catch (error) {
    console.error('Error generating speech:', error);
    throw new Error('Failed to generate speech.');
  }
};

