import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation
import './SpeechToText.css'; // Create a similar CSS file for styling

const SpeechToText = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [convertedText, setConvertedText] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [language, setLanguage] = useState('en-US'); // Default language
  const [errorMessage, setErrorMessage] = useState(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate(); // Hook for navigation

  const API_KEY = 'APY0x6ALFmgXN4t7nn81S3SdqqsP5FVXmr3Bb5NoFs1H5ddn1QCnLwMUqmY3AKtOulE'; // Replace with your actual API key
  const STT_API_URL = 'https://api.apyhub.com/stt/file';

  // Function to handle file selection
  const handleFileSelection = (event) => {
    const file = event.target.files[0];
  
    // Check if the file exists
    if (!file) {
      setErrorMessage('Please select a valid file.');
      return;
    }
  
    // Validate the file type (only accept .wav files)
    if (!file.type.includes('wav')) {
      setErrorMessage('Only .wav audio files are allowed.');
      setSelectedFile(null);
      return;
    }
  
    // Validate file size (limit to 20MB)
    const maxSizeMB = 30;
    const fileSizeMB = file.size / 1024 / 1024;
    if (fileSizeMB > maxSizeMB) {
      setErrorMessage(`Cannot upload .wav audio larger than ${maxSizeMB}MB. Your file size is ${fileSizeMB.toFixed(2)}MB.`);
      setSelectedFile(null);
      return;
    }
  
    // If all validations pass, set the selected file
    setSelectedFile(file);
    setErrorMessage(null); // Clear any previous errors
  };
  

  // Function to handle the language change
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  // Function to handle the speech-to-text conversion
  const handleConvert = async () => {
    if (!selectedFile) {
      setErrorMessage('Please select an audio file to convert.');
      return;
    }

    setIsProcessing(true);
    setErrorMessage(null);

    try {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('language', language);

      const response = await fetch(STT_API_URL, {
        method: 'POST',
        headers: {
          'apy-token': API_KEY,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Conversion failed with status ${response.status}`);
      }

      const data = await response.json();
      setConvertedText(data.data || ''); // Set the converted text from the response
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred during conversion. Please try again later.');
    } finally {
      setIsProcessing(false);
    }
  };

  const resetPage = () => {
    setSelectedFile(null);
    setConvertedText('');
    setIsProcessing(false);
    setErrorMessage(null);

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div className="speech-to-text-container">
      <h1>Speech to Text Converter</h1>
      <p>Convert your audio files into text.</p>

      <div className="file-upload-container">
        <input 
          type="file" 
          accept="audio/*" // Restrict file input to audio files
          onChange={handleFileSelection} 
          ref={fileInputRef}
          className="file-input"
        />
        <button
          className="file-upload-button"
          onClick={() => fileInputRef.current.click()}
          style={{ display: selectedFile ? 'none' : 'block' }}
        >
          Select Audio File
        </button>
      </div>

      {selectedFile && (
        <div className="audio-preview-container" style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="audio-preview">
            <div className="audio-thumbnail">
              <span>FileName: {selectedFile.name}</span>
              <span>FileSize: {(selectedFile.size / 1024 / 1024).toFixed(2)} MB</span>
            </div>
          </div>

          <div className="convert-language-container">
            <label htmlFor="convert-language">Convert from Language:</label>
            <select 
              id="convert-language" 
              value={language} 
              onChange={handleLanguageChange}
            >
              <option value="en-US">English (United States)</option>
              <option value="ta-IN">Tamil (India)</option>
              <option value="hi-IN">Hindi (India)</option>
              <option value="es-ES">Spanish (Spain)</option>
              <option value="fr-FR">French (France)</option>
              <option value="de-DE">German (Germany)</option>
              <option value="zh-CN">Chinese (Mandarin, Simplified)</option>
              {/* Add more languages as required */}
            </select>
          </div>

          <button 
            className="convert-button" 
            onClick={handleConvert}
            disabled={isProcessing} // Disable button while processing
          >
            {isProcessing ? 'Processing...' : 'Convert to Text'}
          </button>
        </div>
      )}

      {convertedText && (
        <div className="converted-text-container">
          <h2>Converted Text:</h2>
          <p>{convertedText}</p>
          <button className="reset-button" onClick={resetPage}>
            Reset
          </button>
        </div>
      )}

      {errorMessage && <div className="error" style={{marginTop: '20px'}}>{errorMessage}</div>}
    </div>
  );
};

export default SpeechToText;
