// SpeechToTextAPI.js
import axios from 'axios';

const STT_API_KEY = 'AIzaSyC8HBUJaTEK_Re23cxfjKtCGMQEw-NkRuc';

// Function to convert audio blob to base64
const audioBlobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const arrayBuffer = reader.result;
      const base64Audio = btoa(
        new Uint8Array(arrayBuffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      );
      resolve(base64Audio);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(blob);
  });
};

// Function to start recording and return the audio stream and media recorder
export const startRecording = async (setRecordingStateCallback) => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const recorder = new MediaRecorder(stream);
    recorder.start();
    setRecordingStateCallback(true);
    console.log('Recording started');

    return { stream, recorder };
  } catch (error) {
    console.error('Error accessing microphone:', error);
    throw error;
  }
};

// Function to handle speech recognition via Google Speech-to-Text API
export const sendAudioToGoogle = async (audioBlob, languageCode = 'en-US') => {
  try {
    const base64Audio = await audioBlobToBase64(audioBlob);

    const response = await axios.post(
      `https://speech.googleapis.com/v1/speech:recognize?key=${STT_API_KEY}`,
      {
        config: {
          encoding: 'WEBM_OPUS', // Adjust based on your format
          sampleRateHertz: 48000, // Match your recording sample rate
          languageCode: languageCode,  // Use selected language
        },
        audio: {
          content: base64Audio,
        },
      },
      { headers: { 'Content-Type': 'application/json' } }
    );

    if (response.data.results && response.data.results.length > 0) {
      return response.data.results[0].alternatives[0].transcript;
    } else {
      return 'No transcription available';
    }
  } catch (error) {
    console.error('Error with Google Speech-to-Text API:', error.response?.data);
    throw error;
  }
};


// Function to stop recording
export const stopRecording = (recorder) => {
  recorder.stop();
};
