import React, { useState, useEffect } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { addDoc, collection } from 'firebase/firestore';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import { saveAs } from 'file-saver';
import { storage, db } from '../../firebaseConfig'; // Firebase setup
import { useNavigate } from 'react-router-dom';
import backIcon from '../../assets/back.png';

const SpeechToTextLive = () => {
  const [transcript, setTranscript] = useState('');
  const [interimTranscript, setInterimTranscript] = useState(''); // To handle interim results
  const [isRecording, setIsRecording] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState(''); // For displaying messages to user
  const [recognitionInstance, setRecognitionInstance] = useState(null); // Store the recognition instance
  const [language, setLanguage] = useState('en-US'); // Default to English
  const [docxFileUrl, setDocxFileUrl] = useState(null); // Store DOCX file URL
  const [isCopied, setIsCopied] = useState(false); // For tracking if link is copied
  const navigate = useNavigate(); // Hook for navigation
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check if the browser supports the Web Speech API
    if (!('webkitSpeechRecognition' in window)) {
      setErrorMessage('Speech recognition not supported in this browser.');
      return;
    }
  
    // Function to handle the window resize event
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);  // Update state to indicate it's a mobile device
      } else {
        setIsMobile(false); // Update state to indicate it's a desktop device
      }
    };
  
    // Set initial state based on the current window size
    handleResize();
  
    // Add the resize event listener
    window.addEventListener('resize', handleResize);
  
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  

  // Start Speech Recognition
  const startRecording = () => {
  if (!('webkitSpeechRecognition' in window)) return;

  const recognition = new window.webkitSpeechRecognition();

  recognition.continuous = true; // Keep listening continuously
  recognition.interimResults = true; // Show real-time results
  recognition.lang = language; // Set the selected language

  recognition.start();
  setIsRecording(true);
  setErrorMessage('');
  setConfirmationMessage('');
  setRecognitionInstance(recognition); // Save the recognition instance

  recognition.onresult = (event) => {
    let interimResult = '';
    for (let i = event.resultIndex; i < event.results.length; ++i) {
      let transcriptPart = event.results[i][0].transcript;

      // Handle special cases where user pronounces punctuation
      transcriptPart = mapSpokenWordsToSymbols(transcriptPart);

      if (event.results[i].isFinal) {
        setTranscript((prev) => prev + transcriptPart + ' ');
        setInterimTranscript(''); // Clear interim when final result is received
      } else {
        interimResult += transcriptPart;
      }
    }
    setInterimTranscript(interimResult); // Set interim transcript for real-time feedback
  };

  recognition.onerror = (event) => {
    setErrorMessage('Error occurred in recognition: ' + event.error);
    stopRecording(); // Stop recording when error occurs
  };

  // Restart recognition on end if user is still speaking
  recognition.onend = () => {
    if (isRecording) {
      recognition.start(); // Restart recognition
    }
  };
};

// // Function to map spoken words to punctuation or syntax
// const mapSpokenWordsToSymbols = (text) => {
//   return text
//     .replace(/\s*\bfull stop\b/gi, '.')  // Ensure no spaces before "."
//     .replace(/\s*\bcomma\b/gi, ', ')     // Ensure no space before ",", space after
//     .replace(/\s*\bquestion mark\b/gi, '? ') // Same for "?"
//     .replace(/\s*\bexclamation mark\b/gi, '! ')
//     .replace(/\s*\bcolon\b/gi, ': ')
//     .replace(/\s*\bsemicolon\b/gi, '; ')
//     .replace(/\s*\bdash\b/gi, '- ')
//     .replace(/\s*\bopen parenthesis\b/gi, ' (')
//     .replace(/\s*\bclose parenthesis\b/gi, ') ')
//     .replace(/\s*\bquotation mark\b/gi, '"');
// };


const mapSpokenWordsToSymbols = (text) => {
  // English mappings
  text = text
    .replace(/full stop/gi, '.')  
    .replace(/comma/gi, ', ')     
    .replace(/question mark/gi, '? ') 
    .replace(/exclamation mark/gi, '! ') 
    .replace(/colon/gi, ': ')     
    .replace(/semicolon/gi, '; ') 
    .replace(/dash/gi, '- ')     
    .replace(/open parenthesis/gi, ' (') 
    .replace(/close parenthesis/gi, ') ') 
    .replace(/quotation mark/gi, '"')    
    .replace(/next line/gi, '\n')    
    .replace(/ellipsis/gi, '... ');    

  // Tamil mappings (without \b)
  text = text
    .replace(/முற்றுப்புள்ளி/gi, '.')  
    .replace(/காற்புள்ளி/gi, ', ')            
    .replace(/கேள்விக்குறி/gi, '? ')   
    .replace(/வியப்புக்குறி/gi, '! ')    
    .replace(/முக்காற்புள்ளி/gi, ': ')          
    .replace(/அரைப்புள்ளி/gi, '; ')     
    .replace(/இடைக்கோடு/gi, '- ')           
    // .replace(/திறந்த\s?கோlam/gi, ' (') 
    // .replace(/மூடிய\s?கோlam/gi, ') ')  
    .replace(/இரட்டைமேற்கோள் குறிகள்/gi, '"') 
    .replace(/தொக்கிக்குறி/gi, '... ') 
    .replace(/புதிய\s?கோடு/gi, '\n'); 
    
  return text;
};


  // Stop Speech Recognition
  const stopRecording = () => {
    if (recognitionInstance) {
      recognitionInstance.stop(); // Stop the recognition using the stored instance
      setIsRecording(false);
    }
  };

  // Function to handle language change
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  // Function to handle transcript text change
  const handleTranscriptChange = (event) => {
    setTranscript(event.target.value);
  };

  // Function to upload DOCX to Firebase and generate link
  const uploadDocxToFirebase = async (buffer) => {
    const fileName = `transcript_${Date.now()}.docx`;
    const storageRef = ref(storage, `transcripts/${fileName}`);

    try {
      const snapshot = await uploadBytes(storageRef, buffer);
      const downloadUrl = await getDownloadURL(snapshot.ref);

      // Store download URL in Firestore
      await addDoc(collection(db, 'transcripts'), {
        downloadUrl,
        timestamp: new Date(),
      });

      return downloadUrl;
    } catch (error) {
      throw new Error('Failed to upload DOCX to Firebase.');
    }
  };

  // Function to download transcript as DOCX and upload it to Firebase
  const downloadAndUploadDocx = async () => {
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              children: [new TextRun(transcript)],
            }),
          ],
        },
      ],
    });

    try {
      const buffer = await Packer.toBlob(doc); // Create the DOCX file
      const downloadUrl = await uploadDocxToFirebase(buffer); // Upload DOCX to Firebase
      setDocxFileUrl(downloadUrl); // Set the download URL for the file
      setConfirmationMessage('Copy DOCX file link and paste it in any browser to download docx file.'); // Show success message
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred during DOCX generation.');
    }
  };

  // Function to copy link to clipboard
  const copyLinkToClipboard = async () => {
    if (!docxFileUrl) {
      setErrorMessage('No link available to copy.');
      return;
    }

    try {
      await navigator.clipboard.writeText(docxFileUrl);
      setIsCopied(true);
      setConfirmationMessage('DOCX file link copied to clipboard!'); // Show confirmation message
    } catch (error) {
      setErrorMessage('Failed to copy link.');
    }
  };

  return (
    <>
    <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '0px' }} onClick={() => navigate('/tools')}>
        <div className="back-button">
          <img src={backIcon} alt='back-btn' style={{ width: '25px', height: '25px' }} />
          <button className='back-sub'>Back</button>
        </div>
      </div>
    <div className="speech-to-text-container" style={styles.container}>
      <h1>Live Speech to Text</h1>
      <p style={{ marginTop: '10px' }}>Convert speech to text real time and download as DOCX or copy text</p>

      <p style={{ color: 'red', marginTop: '30px' }}>
        Note: if you want to translate. use our
        <a href="#!" className="split-link" onClick={() => navigate('/speech-translate')} style={{ color: 'blue', textDecoration: 'none', marginLeft: '5px', marginRight: '5px' }}>
          Speech To Translation
        </a>
        tool to translate your speech text.
      </p>

      {/* Language Selection Dropdown */}
      <div style={styles.languageContainer}>
        <label htmlFor="language-select" style={styles.label}>Choose Language: </label>
        <select id="language-select" value={language} onChange={handleLanguageChange} style={styles.inputField}>
          <option value="en-US">English (US)</option>
          <option value="en-IN">English (India)</option>
          <option value="ta-IN">Tamil (India)</option>
          <option value="te-IN">Telugu (India)</option>
          <option value="ml-IN">Malayalam (India)</option>
          <option value="kn-IN">Kannada (India)</option>
          <option value="hi-IN">Hindi (India)</option>
        </select>
      </div>

      <div style={styles.buttonsContainer}>
        {!isRecording && (
          <button onClick={startRecording} style={styles.button}>
            Start Recording
          </button>
        )}
        {isRecording && (
          <button onClick={stopRecording} style={styles.button}>
            Stop Recording
          </button>
        )}
       {isMobile && (
          <p style={{ color: 'green', marginTop: '30px' }} className="noteStt">
            Note: For better performance use it in desktop
          </p>
        )}
      </div>

      <div style={styles.resultContainer}>
        <h3 style={{ marginBottom: '10px' }}>Transcript:</h3>
        <textarea
          value={transcript + interimTranscript} // Show interim transcript along with the final one
          onChange={handleTranscriptChange}
          style={styles.textarea}
          rows={10}
          placeholder="Transcript will appear here..."
        />
        <p style={styles.interim} id="interim">{interimTranscript}</p> {/* Show interim text */}
      </div>

      {/* Show the download and upload options when transcript is available */}
      {transcript && (
        <div style={styles.downloadButtonContainer}>
          <button onClick={downloadAndUploadDocx} style={styles.button}>
            Download as DOCX
          </button>
        </div>
      )}

      {/* Show the copy link option once the DOCX is uploaded */}
      {docxFileUrl && (
        <div className='share-options'>
          <button onClick={copyLinkToClipboard} className='share-button'>
            {isCopied ? 'Copied!' : 'Copy Link'}
          </button>
        </div>
      )}

{errorMessage && <p style={{ color: 'red', marginTop: '25px' }}>{errorMessage}</p>}
{confirmationMessage && <p style={{ color: 'green', marginTop: '25px' }}>{confirmationMessage}</p>} {/* Show confirmation messages here */}

        {/* <div style={styles.downloadButtonContainer}>
          <button onClick={() => navigate('/tools')} style={styles.button}>
            Go Back
          </button>
        </div> */}
    </div>
    </>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '60px 30px',
  },
  buttonsContainer: {
    margin: '20px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    backgroundColor: '#e74c3c',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    margin: '5px', // Add margin between buttons
  },
  resultContainer: {
    marginTop: '20px',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    maxWidth: '600px',
    width: '100%',
    textAlign: 'left',
  },
  textarea: {
    width: '100%',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '5px',
    border: '1px solid #ddd',
    resize: 'vertical',
  },
  interim: {
    color: 'gray',
    fontStyle: 'italic',
  },
  downloadButtonContainer: {
    marginTop: '20px',
  },
  languageContainer: {
    marginBottom: '10px',
    width: '100%',
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: {
    display: 'block',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  inputField: {
    width: '72%',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '5px',
    border: '1px solid #ddd',
    backgroundColor: '#fff',
  },
};

export default SpeechToTextLive;
