import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import styles from './Forms.module.css'; // Reuse the same CSS module
import { useNavigate } from 'react-router-dom';

import backIcon from '../../assets/back.png';

const Forms = () => {
  const [forms, setForms] = useState([]);
  const [filteredForms, setFilteredForms] = useState([]); // To store filtered results
  const [searchTerm, setSearchTerm] = useState('');
  const [copiedFormId, setCopiedFormId] = useState(null); // Track which form's link was copied
  const [loading, setLoading] = useState(true); // New state to track loading
  const navigate = useNavigate(); // Hook for navigation
  
  useEffect(() => {
    fetchForms();
  }, []);

  // Fetch forms from Firestore
  const fetchForms = async () => {
    setLoading(true); // Set loading to true before fetching forms
    try {
      // Order by "title" instead of "createdAt" for alphabetical sorting
      let formsQuery = query(collection(db, "forms"), orderBy("title", "asc")); // Order by title alphabetically
      const snapshot = await getDocs(formsQuery);
      const formsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setForms(formsData);
      setFilteredForms(formsData); // Set filtered forms initially to all fetched forms
    } catch (error) {
      console.error("Error fetching forms:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  // Handle real-time search functionality
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    const filtered = forms.filter(form => {
      // Check if the form title matches the search term
      return form.title.toLowerCase().includes(value.toLowerCase());
    });

    setFilteredForms(filtered); // Update the filtered forms based on search
  };

  // Function to handle link copy to clipboard
  const copyLinkToClipboard = async (pdfUrl, formId) => {
    try {
      await navigator.clipboard.writeText(pdfUrl);
      setCopiedFormId(formId); // Set the copied form ID to show "Copied"
    } catch (error) {
      console.error('Failed to copy link:', error);
    }
  };

  return (
    <>
    <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '0px' }} onClick={() => navigate('/tools')}>
      <div className="back-button">
        <img src={backIcon} alt='back-btn' style={{width: '25px', height: '25px'}} />
        <button className='back-sub'>Back</button>
      </div>
    </div>
    <div className={styles.container}>
      <h1 style={{marginBottom: 10}}>Forms</h1>
      <p style={{marginBottom: 10}}>Search forms quickly by name</p>

      <input
        type="text"
        placeholder="Search by title..."
        value={searchTerm}
        onChange={handleSearch} 
        className={styles.inputField}
      />

      <div className={styles.results}>
        {loading ? (
          <p>Loading forms...</p>  
        ) : filteredForms.length > 0 ? (
          filteredForms.map(form => (
            <div key={form.id} className={styles.siteItem}>
              <div style={{display: 'flex', alignItems: 'center', gap: 20, marginBottom: 10}}>
              <div>
                <p style={{fontSize: '16px'}}>{form.title}</p>
              </div>
              <button
                onClick={() => copyLinkToClipboard(form.pdfUrl, form.id)}
                className={styles.button}
              >
                {copiedFormId === form.id ? 'Copied' : 'Copy Link'}
              </button>
              </div>
              {copiedFormId === form.id && (
                <p style={{ marginTop: '5px', fontStyle: 'italic', fontSize: '14px', color: 'green' }}>
                  Link copied. Paste it in any browser to download the form.
                </p>
              )}
            </div>
          ))
        ) : (
          <div style={{textAlign: 'center', display: 'block'}}>
            <p className={styles.noResults}>No results found</p> 
          </div>
        )}
      </div>
    </div>
    </>
  );
};

export default Forms;
