import React, { useState, useRef, useEffect } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { addDoc, collection, doc, getDoc, updateDoc, query, where, getDocs } from 'firebase/firestore';
import { storage, db, auth } from '../../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { startRecording, stopRecording, sendAudioToGoogle } from './SpeechToTextAPI';
import axios from 'axios';
import backIcon from '../../assets/back.png';
import { Document, Packer, Paragraph, TextRun } from 'docx';

const CHATGPT_API_KEY = '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';
const CHATGPT_API_URL = 'https://api.openai.com/v1/chat/completions';

const GoogleSTT = () => {
  const [transcript, setTranscript] = useState('');
  const [translatedText, setTranslatedText] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [isConvertingSTT, setIsConvertingSTT] = useState(false);
  const [isConvertingTranslation, setIsConvertingTranslation] = useState(false);
  const [isDownloadingTranscript, setIsDownloadingTranscript] = useState(false);
  const [isDownloadingTranslation, setIsDownloadingTranslation] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [docxFileUrl, setDocxFileUrl] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [language, setLanguage] = useState('en-US');
  const [targetLanguage, setTargetLanguage] = useState('en');
  const [toolPrice, setToolPrice] = useState(null);
  const [wordCount, setWordCount] = useState(0);
  const [coinsDeducted, setCoinsDeducted] = useState(0);
  const recorderRef = useRef(null);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false); 

  useEffect(() => {
    // Fetch tool price from Firestore
    const fetchToolPrice = async () => {
      try {
        const toolsCollection = collection(db, 'toolsPrice');
        const q = query(toolsCollection, where('tool', '==', 'Speech To Translation'));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const toolDoc = querySnapshot.docs[0];
          setToolPrice(toolDoc.data().price);
        } else {
          setErrorMessage('Tool price not found.');
        }
      } catch (error) {
        setErrorMessage('Error fetching tool price.');
      }
    };

    fetchToolPrice();
  }, []);

  // Function to handle translation with ChatGPT
  const translateTextWithChatGPT = async (text, targetLang) => {
    setIsConvertingTranslation(true);
    const prompt = `Translate the following text to ${targetLang}: ${text}`;

    try {
      const response = await axios.post(
        CHATGPT_API_URL,
        {
          model: "gpt-4",
          messages: [
            { role: "system", content: "Translate text precisely and fully." },
            { role: "user", content: prompt }
          ],
          max_tokens: 500
        },
        {
          headers: {
            'Authorization': `Bearer ${CHATGPT_API_KEY}`,
            'Content-Type': 'application/json'
          }
        }
      );

      const translatedText = response.data.choices[0].message.content.trim();
      setIsConvertingTranslation(false);
      return translatedText;
    } catch (error) {
      setIsConvertingTranslation(false);
      setErrorMessage('Translation failed. Please try again later.');
      console.error('Error with ChatGPT Translation:', error);
      return '';
    }
  };

  // Function to open modal for confirmation before starting the recording
    const confirmRecording = () => {
        setIsModalOpen(true);
    };

    const handleStartRecording = async () => {
        const userId = auth.currentUser?.uid;
      
        if (!userId) {
          setErrorMessage("User is not authenticated. Please log in.");
          return;
        }
      
        try {
          // Fetch user balance from Firestore
          const userDocRef = doc(db, 'users', userId);
          const userDoc = await getDoc(userDocRef);
      
          if (userDoc.exists()) {
            const currentBalance = userDoc.data().balance || 0;
      
            // Check if balance is at least 50 coins
            if (currentBalance >= 50) {
              // Close modal and start recording
              setIsModalOpen(false);
              await startGoogleRecording();
            } else {
              setIsModalOpen(false);
              setErrorMessage("Insufficient balance. To start recording, your wallet must have at least 50 coins. Recharge your wallet in lexdoo app to proceed.");
            }
          } else {
            setIsModalOpen(false);
            setErrorMessage("User document not found.");
          }
        } catch (error) {
          setIsModalOpen(false);
          setErrorMessage("Error fetching user balance. Please try again.");
        }
      };
      
  
//   // Function to handle starting recording after confirmation
//   const handleStartRecording = async () => {
//     setIsModalOpen(false); // Close modal after confirmation
//     await startGoogleRecording();
//   };

// const startGoogleRecording = async () => {
//     setErrorMessage('');
//     setConfirmationMessage('');

//     // Get the current authenticated user's ID
//     const userId = auth.currentUser?.uid;
//     if (!userId) {
//       setErrorMessage("User is not authenticated. Please log in.");
//       return;
//     }

//     try {
//       const { recorder } = await startRecording(setIsRecording);
//       recorderRef.current = recorder;

//       recorderRef.current.addEventListener(
//         'dataavailable',
//         async (event) => {
//           const audioBlob = event.data;
//           if (audioBlob && audioBlob.size > 0) {
//             setIsConvertingSTT(true);
//             try {
//               // Get transcript from audio
//               const transcription = await sendAudioToGoogle(audioBlob, language);
//               setTranscript(transcription);

//               // Calculate word count and coins to deduct per word based on tool price
//               const wordCount = transcription.split(/\s+/).length;
//               setWordCount(wordCount);
//               const coinsPerWord = toolPrice / 50;
//               const coinsToDeduct = wordCount * coinsPerWord; 

//               // Deduct calculated coins based on word count
//               const userDocRef = doc(db, 'users', userId);
//               const userDoc = await getDoc(userDocRef);
//               if (userDoc.exists()) {
//                 const currentBalance = userDoc.data().balance || 0;

//                 if (currentBalance >= coinsToDeduct) {
//                   await updateDoc(userDocRef, { balance: currentBalance - coinsToDeduct });
//                   setCoinsDeducted(coinsToDeduct);
//                   setConfirmationMessage(`Deducted ${coinsToDeduct.toFixed(2)} coins from your wallet.`);

//                   // Retrieve translation after deduction
//                   const translated = await translateTextWithChatGPT(transcription, targetLanguage);
//                   setTranslatedText(translated);
//                 } else {
//                   setErrorMessage('Insufficient balance.');
//                 }
//               } else {
//                 setErrorMessage('User document not found.');
//               }
//             } catch (error) {
//               setErrorMessage('Error processing transcription or translation: ' + error.message);
//             } finally {
//               setIsConvertingSTT(false);
//             }
//           } else {
//             setErrorMessage('No audio data available for transcription.');
//           }
//         },
//         { once: true }
//       );
//     } catch (error) {
//       setErrorMessage('Failed to start recording: ' + error.message);
//       setIsConvertingSTT(false);
//     }
// };

const startGoogleRecording = async () => {
    setErrorMessage('');
    setConfirmationMessage('');
    const userId = auth.currentUser?.uid;
    if (!userId) {
      setErrorMessage("User is not authenticated. Please log in.");
      return;
    }

    try {
      const { recorder } = await startRecording(setIsRecording);
      recorderRef.current = recorder;

      recorderRef.current.addEventListener(
        'dataavailable',
        async (event) => {
          const audioBlob = event.data;
          if (audioBlob && audioBlob.size > 0) {
            setIsConvertingSTT(true);
            try {
              const transcription = await sendAudioToGoogle(audioBlob, language);
              let words = transcription.split(/\s+/);
              let processedTranscript = "";
              let totalDeductedCoins = 0;

              for (let i = 0; i < words.length; i += 50) {
                const segmentWords = words.slice(i, i + 50).join(" ");
                processedTranscript += ` ${segmentWords}`;

                const userDocRef = doc(db, 'users', userId);
                const userDoc = await getDoc(userDocRef);
                const currentBalance = userDoc.data().balance || 0;

                // Deduct coins incrementally for each 50-word segment
                if (currentBalance >= toolPrice) {
                  await updateDoc(userDocRef, { balance: currentBalance - toolPrice });
                  totalDeductedCoins += toolPrice;
                } else {
                  setErrorMessage("Insufficient balance. Transcription stopped.");
                  break;
                }
              }

              setTranscript(processedTranscript.trim());
              setCoinsDeducted(totalDeductedCoins);

              // Proceed to translation if there is enough balance
              if (totalDeductedCoins > 0) {
                const translated = await translateTextWithChatGPT(processedTranscript, targetLanguage);
                setTranslatedText(translated);
              }

            } catch (error) {
              setErrorMessage('Error processing transcription or translation: ' + error.message);
            } finally {
              setIsConvertingSTT(false);
            }
          } else {
            setErrorMessage('No audio data available for transcription.');
          }
        },
        { once: true }
      );
    } catch (error) {
      setErrorMessage('Failed to start recording: ' + error.message);
      setIsConvertingSTT(false);
    }
};

  

  const stopGoogleRecording = () => {
    if (recorderRef.current) {
      recorderRef.current.stop();
      setIsRecording(false);
      recorderRef.current = null;
    }
  };
  

  // Function to handle language change for Speech-to-Text
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  // Function to handle translation target language change
  const handleTargetLanguageChange = (event) => {
    setTargetLanguage(event.target.value);
  };

  // Function to upload DOCX to Firebase
  const uploadDocxToFirebase = async (buffer) => {
    const fileName = `transcript_${Date.now()}.docx`;
    const storageRef = ref(storage, `transcripts/${fileName}`);

    try {
      const snapshot = await uploadBytes(storageRef, buffer);
      const downloadUrl = await getDownloadURL(snapshot.ref);

      await addDoc(collection(db, 'transcripts'), {
        downloadUrl,
        timestamp: new Date(),
      });

      return downloadUrl;
    } catch (error) {
      throw new Error('Failed to upload DOCX to Firebase.');
    }
  };

  // Function to download DOCX for a specific text (transcript or translated text)
  const downloadAsDocx = async (text, filename, isDownloadingStateSetter) => {
    setIsCopied(false); // Reset "Copy Link" state
    isDownloadingStateSetter(true); // Start download
    const doc = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              children: [new TextRun(text)],
            }),
          ],
        },
      ],
    });

    try {
      const buffer = await Packer.toBlob(doc);
      const downloadUrl = await uploadDocxToFirebase(buffer);
      setDocxFileUrl(downloadUrl);
      setConfirmationMessage(`${filename} downloaded successfully.`);
    } catch (error) {
      setErrorMessage('An error occurred during DOCX generation.');
    } finally {
      isDownloadingStateSetter(false); // End download
    }
  };

  // Function to copy link to clipboard
  const copyLinkToClipboard = async () => {
    if (!docxFileUrl) {
      setErrorMessage('No link available to copy.');
      return;
    }

    try {
      await navigator.clipboard.writeText(docxFileUrl);
      setIsCopied(true);
      setConfirmationMessage('DOCX file link copied to clipboard!');
    } catch (error) {
      setErrorMessage('Failed to copy link.');
    }
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '0px' }} onClick={() => navigate('/tools')}>
        <div className="back-button">
          <img src={backIcon} alt='back-btn' style={{ width: '25px', height: '25px' }} />
          <button className='back-sub'>Back</button>
        </div>
      </div>
      <div className="speech-to-text-container" style={styles.container}>
        <h1>Speech to Translated Text</h1>
        <p>Convert speech to translated text and download as DOCX</p>

        <p style={{ color: 'red', marginTop: '20px' }} className="noteStt">
            Note: To start recording, your wallet must have at least 50 coins.
      </p>

        <div style={styles.languageContainer}>
          <label htmlFor="language-select" style={styles.label}>Choose Speech Recognition Language: </label>
          <select id="language-select" value={language} onChange={(e) => setLanguage(e.target.value)} style={styles.inputField}>
            <option value="en-US">English (US)</option>
            <option value="en-IN">English (India)</option>
            <option value="ta-IN">Tamil (India)</option>
            <option value="te-IN">Telugu (India)</option>
            <option value="ml-IN">Malayalam (India)</option>
            <option value="kn-IN">Kannada (India)</option>
            <option value="hi-IN">Hindi (India)</option>
          </select>
        </div>

        <div style={styles.languageContainer}>
          <label htmlFor="target-language-select" style={styles.label}>Choose Translation Language: </label>
          <select id="target-language-select" value={targetLanguage} onChange={(e) => setTargetLanguage(e.target.value)} style={styles.inputField}>
            <option value="en">English</option>
            <option value="hi">Hindi</option>
            <option value="ta">Tamil</option>
            <option value="te">Telugu</option>
            <option value="ml">Malayalam</option>
            <option value="kn">Kannada</option>
            <option value="es">Spanish</option>
            <option value="fr">French</option>
            <option value="de">German</option>
          </select>
        </div>

        <div style={styles.buttonsContainer}>
          {!isRecording && (
            <button onClick={confirmRecording} style={styles.button}>
              Start Recording
            </button>
          )}
          {isRecording && (
            <button onClick={stopGoogleRecording} style={styles.button}>
              Stop Recording
            </button>
          )}
        </div>

        {errorMessage && <p style={{ color: 'red', marginTop: '25px' }}>{errorMessage}</p>}

        {wordCount > 0 && (
          <p style={{ color: 'green', marginTop: '25px' }}>Words Count: {wordCount} | Coins Deducted: {coinsDeducted}</p>
        )}



        <div style={styles.resultContainer}>
          <h3>Original Transcript:</h3>
          <textarea
            value={transcript}
            onChange={(e) => setTranscript(e.target.value)}
            style={styles.textarea}
            rows={5}
            placeholder={isConvertingSTT ? 'Converting...' : 'Transcript will appear here...'}
          />
          {transcript && (
            <div style={styles.downloadButtonContainer}>
              <button
                onClick={() => downloadAsDocx(transcript, 'Original Transcript', setIsDownloadingTranscript)}
                style={styles.button}
                disabled={isDownloadingTranscript}
              >
                {isDownloadingTranscript ? 'Downloading...' : 'Download Original Transcript as DOCX'}
              </button>
            </div>
          )}
        </div>

        <div style={styles.resultContainer}>
          <h3>Translated Text:</h3>
          <textarea
            value={translatedText}
            onChange={(e) => setTranslatedText(e.target.value)}
            style={styles.textarea}
            rows={5}
            placeholder={isConvertingTranslation ? 'Translating...' : 'Translation will appear here...'}
          />
          {translatedText && (
            <div style={styles.downloadButtonContainer}>
              <button
                onClick={() => downloadAsDocx(translatedText, 'Translated Text', setIsDownloadingTranslation)}
                style={styles.button}
                disabled={isDownloadingTranslation}
              >
                {isDownloadingTranslation ? 'Downloading...' : 'Download Translated Text as DOCX'}
              </button>
            </div>
          )}
        </div>

        {docxFileUrl && (
          <div className='share-options'>
            <button onClick={copyLinkToClipboard} className='share-button'>
              {isCopied ? 'Copied!' : 'Copy Link'}
            </button>
          </div>
        )}

        {confirmationMessage && <p style={{ color: 'green', marginTop: '25px' }}>{confirmationMessage}</p>}

        {isModalOpen && (
        <div className="modal">
            <div className="modal-content">
            <p>For every 50 words, {toolPrice} coins will be deducted from your wallet. Do you want to proceed?</p>
            <div className="modal-buttons">
                <button className="modal-confirm" onClick={handleStartRecording}>Ok</button>
                <button className="modal-cancel" onClick={() => setIsModalOpen(false)}>Cancel</button>
            </div>
            </div>
        </div>
        )}
      </div>
    </>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '60px 30px',
  },
  buttonsContainer: {
    margin: '20px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    backgroundColor: '#e74c3c',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    margin: '5px',
  },
  resultContainer: {
    marginTop: '20px',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    maxWidth: '600px',
    width: '100%',
    textAlign: 'left',
  },
  textarea: {
    width: '100%',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '5px',
    border: '1px solid #ddd',
    resize: 'vertical',
  },
  languageContainer: {
    marginBottom: '10px',
    width: '100%',
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: {
    display: 'block',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  inputField: {
    width: '72%',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '5px',
    border: '1px solid #ddd',
    backgroundColor: '#fff',
  },
};

export default GoogleSTT;
