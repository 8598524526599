import React, { useState, useRef, useEffect } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { addDoc, collection, getDoc, doc, updateDoc, query, where, getDocs } from 'firebase/firestore';
import { storage, db, auth } from '../../firebaseConfig'; // Firebase setup
import './ImageToPdf.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';

import backIcon from '../../assets/back.png'

const ImageToPdf = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [pdfFileUrl, setPdfFileUrl] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [orientation, setOrientation] = useState('portrait');
  const [margin, setMargin] = useState(0);
  const [pageSize, setPageSize] = useState('fit');
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [toolPrice, setToolPrice] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false); // Track copy status
  const fileInputRef = useRef(null);
  const navigate = useNavigate(); // Hook for navigation

  const PUBLIC_KEY = 'project_public_89bba9932e7a274620c5d22eb4ce981a_9dOWN7a1ea85b9a31ff5f2d6d0d3924dc594b';
  const AUTH_API_URL = 'https://api.ilovepdf.com/v1/auth';

  useEffect(() => {
    // Fetch tool price from Firestore
    const fetchToolPrice = async () => {
      try {
        const toolsCollection = collection(db, 'toolsPrice');
        const q = query(toolsCollection, where('tool', '==', 'Image to PDF'));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const toolDoc = querySnapshot.docs[0];
          setToolPrice(toolDoc.data().price); // Set tool price from Firestore
        } else {
          setErrorMessage('Tool price not found.');
        }
      } catch (error) {
        setErrorMessage('Error fetching tool price.');
      }
    };

    fetchToolPrice();
  }, []);

  const handleFileSelection = (event) => {
    setSelectedFiles([...event.target.files]);
    setPdfFileUrl(null);
    setErrorMessage(null);
    setConfirmationMessage('');
    setIsCopied(false); // Reset copy status
  };

  const handleOrientationChange = (event) => {
    setOrientation(event.target.value);
  };

  const handleMarginChange = (event) => {
    setMargin(event.target.value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
  };

  const confirmProcess = () => {
    setIsModalOpen(true); // Open confirmation modal
  };

  const handleConvert = async () => {
    if (selectedFiles.length === 0) {
      setErrorMessage('Please select images to convert.');
      return;
    }

    setIsProcessing(true);
    setErrorMessage(null);
    setIsModalOpen(false);

    try {
      const token = await getToken();
      const { server, task } = await startTask(token, 'imagepdf');

      const serverFilenames = await uploadFiles(server, task, selectedFiles, token);

      // Process the files and get the task for download
      const pdfBlob = await processFiles(server, task, serverFilenames, selectedFiles.map(file => file.name), token);

      // Upload the PDF to Firebase and store the URL
      const downloadUrl = await uploadPdfToFirebase(pdfBlob);

      setPdfFileUrl(downloadUrl); // Set the download URL for display
      setConfirmationMessage(`${toolPrice} coins have been deducted from your wallet.`);
      await deductAmountFromWallet(auth.currentUser.uid, toolPrice);
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred while converting the images. Please try again later.');
    } finally {
      setIsProcessing(false);
      setIsModalOpen(false); // Close modal
    }
  };

  const resetPage = () => {
    setSelectedFiles([]);
    setPdfFileUrl(null);
    setIsProcessing(false);
    setErrorMessage(null);
    setConfirmationMessage('');
    setIsCopied(false); // Reset copy status

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const getToken = async () => {
    const response = await fetch(AUTH_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ public_key: PUBLIC_KEY })
    });

    const data = await response.json();
    return data.token;
  };

  const startTask = async (token, tool) => {
    const response = await fetch(`https://api.ilovepdf.com/v1/start/${tool}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    const text = await response.text();
    if (!response.ok || !text) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    const data = JSON.parse(text);
    if (!data.server || !data.task) {
      throw new Error('Failed to retrieve server or task ID from response');
    }

    return data;
  };

  const uploadFiles = async (server, task, files, token) => {
    const uploadedFilenames = [];

    for (const file of files) {
      const formData = new FormData();
      formData.append('task', task);
      formData.append('file', file);

      const response = await fetch(`https://${server}/v1/upload`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error(`Upload failed with status ${response.status}`);
      }

      const data = await response.json();
      uploadedFilenames.push(data.server_filename);
    }

    return uploadedFilenames;
  };

  const processFiles = async (server, task, serverFilenames, originalFilenames, token) => {
    const requestBody = {
      task: task,
      tool: 'imagepdf',
      files: serverFilenames.map((filename, index) => ({
        server_filename: filename,
        filename: originalFilenames[index],
      })),
      orientation,
      margin: parseInt(margin, 10),
      pagesize: pageSize,
      merge_after: true, // Enable merging after conversion
    };

    const response = await fetch(`https://${server}/v1/process`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Process failed with status ${response.status}: ${errorText}`);
    }

    return downloadFile(server, task, token); // Now download the PDF file
  };

  const downloadFile = async (server, task, token) => {
    const response = await fetch(`https://${server}/v1/download/${task}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Download failed with status ${response.status}`);
    }

    const blob = await response.blob(); // Get the actual PDF blob
    return blob; // Return the PDF blob
  };

  const uploadPdfToFirebase = async (pdfBlob) => {
    try {
      const fileName = `converted_pdf_${Date.now()}.pdf`;
      const storageRef = ref(storage, `convertedPdfs/${fileName}`);
      const snapshot = await uploadBytes(storageRef, pdfBlob); // Upload the PDF blob to Firebase Storage
      const downloadUrl = await getDownloadURL(snapshot.ref); // Get the file's download URL

      // Store the download URL in Firestore
      await addDoc(collection(db, 'convertedPdfs'), {
        downloadUrl,
        timestamp: new Date(),
      });

      return downloadUrl;
    } catch (error) {
      throw new Error('Failed to upload converted PDF to Firebase.');
    }
  };

  const deductAmountFromWallet = async (userId, price) => {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const currentBalance = userData.balance || 0;

      if (currentBalance >= price) {
        const newBalance = currentBalance - price;
        await updateDoc(userDocRef, { balance: newBalance });
      } else {
        throw new Error('Insufficient coins in your wallet.');
      }
    } else {
      throw new Error('User not found.');
    }
  };

  const copyLinkToClipboard = async () => {
    if (!pdfFileUrl) {
      setConfirmationMessage('No link to copy.');
      return;
    }

    try {
      await navigator.clipboard.writeText(pdfFileUrl);
      setIsCopied(true); // Set to true when the link is copied
      setConfirmationMessage('Converted PDF link copied to clipboard. paste copied link in any browser to download pdf.');
    } catch (error) {
      setConfirmationMessage('Failed to copy link.');
    }
  };

  return (
    <>
     <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '0px' }} onClick={() => navigate('/tools')}>
    <div className="back-button">
    <img src={backIcon} alt='back-btn' style={{width: '25px', height: '25px'}} />
    <button className='back-sub'>Back</button>
    </div>
    </div>
    <div className="crop-image-container">
      <h1>Convert Images to PDF</h1>
      <p>Convert your jpg or jpeg into a single PDF file with various settings.</p>

      <div className="file-upload-container">
        <input
          type="file"
          accept='.jpg,.jpeg'
          multiple
          onChange={handleFileSelection}
          ref={fileInputRef}
          className="file-input"
        />
        <button
          className="file-upload-button"
          onClick={() => fileInputRef.current.click()}
          style={{ display: selectedFiles.length > 0 ? 'none' : 'block' }}
        >
          Select Images
        </button>
      </div>

      {selectedFiles.length > 0 && (
        <div className="crop-image-container" style={{paddingBottom: 0, paddingTop: 0}}>
          {selectedFiles.map((file, index) => (
            <div key={index} className="image-preview">
              <img src={URL.createObjectURL(file)} alt={`Preview ${index}`} />
              <span>FileName: {file.name}</span>
              <span>FileSize: {(file.size / 1024 / 1024).toFixed(2)} MB</span>
            </div>
          ))}

          <div className="pdf-settings">
            <label>
              Orientation:
              <select value={orientation} onChange={handleOrientationChange}>
                <option value="portrait">Portrait</option>
                <option value="landscape">Landscape</option>
              </select>
            </label>

            <label>
              Margin (px):
              <input
                type="number"
                value={margin}
                onChange={handleMarginChange}
                min="0"
                max="100"
              />
            </label>

            <label>
              Page Size:
              <select value={pageSize} onChange={handlePageSizeChange}>
                <option value="fit">Fit</option>
                <option value="A4">A4</option>
                <option value="letter">Letter</option>
              </select>
            </label>
          </div>

          <button
            className="crop-button"
            onClick={confirmProcess}
            disabled={isProcessing}
          >
            {isProcessing ? 'Processing...' : 'Convert to PDF'}
          </button>
           {pdfFileUrl && (
            <div className="share-options">
              <button className="share-button" onClick={copyLinkToClipboard}>
                {isCopied ? 'Copied!' : 'Copy Link'}
             </button>
            </div>
             )}
        </div>
        
      )}

      {confirmationMessage && <div className="confirmation-message" style={{ marginTop: '25px' }}>{confirmationMessage}</div>}
      {errorMessage && <div className="error-message" style={{ marginTop: '25px' }}>{errorMessage}</div>}
     

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <p>Are you sure you want to deduct {toolPrice} coins for the PDF conversion?</p>
            <div className="modal-buttons">
              <button className="modal-confirm" onClick={handleConvert}>Ok</button>
              <button className="modal-cancel" onClick={() => setIsModalOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      
    </div>
    </>
  );
};

export default ImageToPdf;
